var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("h4", { staticClass: "card-title mb-50" }, [
            _vm._v(" New Technologies Data "),
          ]),
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(186, 191, 199, 0.15)",
                      expression: "'rgba(186, 191, 199, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: { variant: "outline-primary" },
                },
                [_vm._v(" Daily ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(186, 191, 199, 0.15)",
                      expression: "'rgba(186, 191, 199, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: { variant: "outline-primary" },
                },
                [_vm._v(" Monthly ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(186, 191, 199, 0.15)",
                      expression: "'rgba(186, 191, 199, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: { variant: "outline-primary" },
                },
                [_vm._v(" Yearly ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("vue-apex-charts", {
            attrs: {
              type: "scatter",
              height: "400",
              options: _vm.apexChatData.scatterChart.chartOptions,
              series: _vm.apexChatData.scatterChart.series,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }